<template>
  <svg>
    <defs>
      <path id="prefix__a" d="M0 0H25.3V27.036H0z" />
      <path id="prefix__c" d="M0 0.008L24.738 0.008 24.738 15.237 0 15.237z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlink:href="#prefix__a" />
      </mask>
      <use fill="#FFF" xlink:href="#prefix__a" />
      <g mask="url(#prefix__b)">
        <g>
          <path
            fill="#970D0D"
            d="M7.88 28.024l-.275.191-.219.22.247 3.755.246 4.25.247 3.672.274 4.359.055.795s1.453.411 1.645.438c0 0 1.343.275 1.782.302 0 0 3.613.384 5.466 0l1.579-.192s2.302-.11 3.673.027c0 0 2.028.192 2.44.247 0 0 .986.192 2.247.22 0 0 4.222.027 4.578-.33 0 0-.658-.438-1.617-2.055l-2.933-4.688s-.85-1.288-1.371-2.604c0 0-1.316-3.4-1.453-3.947l-1.014-3.125-1.151-3.893-.412-1.48-1.37-2.906-1.151-2.22-3.29-.247-6.305.357-2.576 1.7 2.494 3.81-.877 2.932-.96.412z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#2E3338"
            d="M19.255 22.514s1.207-.11 2.44.247c0 0 1.179.438 1.426.794l-6.004 3.153s-1.973 1.014-2.357 1.288l-.576.384-.658-.685-.987.685-.712-1.288 1.37-1.097-1.562.384-.055-.494 1.864-.685.822 1.618 4.99-4.304z"
            transform="translate(0 2.2)"
          />
          <g transform="translate(0 2.2) translate(0 11.197)">
            <mask id="prefix__d" fill="#fff">
              <use xlink:href="#prefix__c" />
            </mask>
            <path
              fill="#E12221"
              d="M6.4 2.326s-.74.356-1.097.548c0 0-.658.356-.932.768L2.342 6.93S.752 9.7.615 9.919c0 0-.575.987-.603 1.179 0 0-.027.082 0 .137l1.124.904s.63-.63 1.097-.767c0 0 1.206-.439 1.644-.33v-.054l1.37-1.425s.96 2.85 1.125 3.262l.52 1.315.494.932.302.165.657-.137.247.055v-.137s2.22-.302 2.988-.11v-.22l1.864-.685.274.549 4.057-5.538 1.535 2.276s1.645 0 2.66.41c0 0 .863.37 1.15.659l1.618-.823-4.084-7.867s-.083-.302-2.961-2.029l-.439-.274L14.541.078s-.275-.11-.302-.055c-.027.055-4.824.685-4.824.685S6.454 1.942 6.399 2.326"
              mask="url(#prefix__d)"
            />
          </g>
          <path
            fill="#740908"
            d="M6.427 13.386v.11l.822.465s1.7.082 1.864.028c.164-.055 0-.028 0-.028l-.658-.192-1.206-.137-.822-.246z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#A4100E"
            d="M9.415 11.905S7.002 12.947 6.59 13.25l-.164.137s.767.493.822.493c.055 0 .63.082.905.082h.986l.165-1.782h.11v-.274zM14.24 11.22s.164-.082.547.137l2.413 1.179-1.947 1.343s-.356.247-.822.247l-1.316-.055 1.124-2.851z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#770908"
            d="M17.885 12.947l-.685-.439-1.837 1.316s-.411.274-.905.302l-1.343-.055 1.261.11s.548.027.987-.275l1.837-1.315.685.356z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#323236"
            d="M9.415 12.18h-.11L9.14 13.96l3.975.11.823-2.166-.713 1.563-.205.334s-1.726.108-2.954.065c0 0-.651-.098-.706-.152 0 0 0-.905.055-1.536"
            transform="translate(0 2.2)"
          />
          <path
            fill="#2D3237"
            d="M1.136 23.336s.685-.904 1.919-1.069c0 0 .658-.082.822-.027l2.66 3.783.136-.768 1.864.52.055.522-1.233-.137.986.685-.685 1.7-1.26-.549-.796-1.069-4.468-3.59z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#F8AD87"
            d="M9.689 1.544s1.151.41 1.206.41l3.152.056.603.466-.082 1.069-.055 1.233.028 1.015-.055.576-.247 4.878-1.179 2.55s-2.33.301-3.728-.055l.083-1.837.137-.63s-1.398-.082-1.234-1.07c0 0 .137-.3.247-.438l-.603-2.412s-.274-.411.082-1.206c0 0 .301-.274.164-.987l-.137-.932.576-1.261 1.042-1.425z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#171A1D"
            d="M8.236 5.272s-.33-1.207-.302-1.727c0 0 0-.549.302-.823.301-.274.877-.767.877-.767s-.603-.576-.11-1.563c0 0 .192-.438.603-.356 0 0 2.66.466 4.935 1.508l1.37 1.754s-.192 1.042-1.37 2.494l-.247.357s-.055-2.056-.137-2.632c0 0-.713-.329-.11-.603l.302-.082s-.713-.576-2.358-.576c0 0-1.672.11-1.727.137 0 0-.438-.356-.548-.548 0 0-1.562 1.782-1.48 3.427"
            transform="translate(0 2.2)"
          />
          <path
            fill="#171A1D"
            d="M8.357 4.288s.317-.47.564-.606c0 0 .466-.22 1.234-.22.027 0 .301.274.301.274l-.027.083s-.905-.028-1.152.027c0 0-.388.062-.92.442M11.608 3.572s.685.055 1.096.137c0 0 .713.165.905.548 0 0-.302-.027-.494-.137 0 0-.274-.192-.74-.192h-.987l.22-.356z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#D27E61"
            d="M9.963 8.095s.22.137.438.192c0 0 .768.22 1.289-.11 0 0 .274-.164.438-.356 0 0-.52.329-.877.356 0 0-.767.137-1.288-.082"
            transform="translate(0 2.2)"
          />
          <path
            fill="#E89874"
            d="M8.4 10.727s.63.329 2.413.246c0 0 1.562.055 1.891-.493 0 0 .356-.713.302-1.617l-.055-1.508s1.041-1.124 1.562-1.261v.274s.247-.603.713-.439c0 0 .493.247.466.905 0 0-.082.822-.713 1.179 0 0-.247.22-.575.11l-.165 3.097-1.014 2.248-3.646-1.946-.027-.247s-.96-.11-1.152-.548"
            transform="translate(0 2.2)"
          />
          <path
            fill="#B56863"
            d="M14.513 7.218s-.11-.768.439-1.014c0 0 .466-.247.548.466 0 0 0 .822-.439 1.096 0 0-.301.165-.52.055 0 0 .685-.055.52-.494 0 0-.246-.575-.548-.11"
            transform="translate(0 2.2)"
          />
          <path
            fill="#100A08"
            d="M11.553 5.326s.055-.274.548-.356c0 0 .658-.137.877.165 0 0 .247.301.22.603 0 0-.631.356-1.453.082 0 0-.11-.028-.137-.192l-.055-.302z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#030302"
            d="M10.511 5.326s.082-.219-.384-.356c0 0-.575-.192-.932 0 0 0-.329.247-.329.658 0 0 0 .055.33.164 0 0 .602.137 1.04.028l.056-.028s.219-.41.219-.466"
            transform="translate(0 2.2)"
          />
          <path
            fill="#CA7251"
            d="M14.294 8.397c.009-.055-.082 2.878-.082 2.878l-1.015 2.248-3.7-2.001.055-.247s.877.192 1.973.055c0 0 1.07-.083 1.37-.658 0 0 1.371-2.111 1.399-2.275"
            transform="translate(0 2.2)"
          />
          <path
            fill="#E08F6B"
            d="M8.263 5.573s-.301-.247-.548-.027c0 0-.439.301-.329.96 0 0 .165.52.548.876 0 0 .055.11.11.302l.493 2-.246-1.863s.219-.384.054-.713l-.438-.63s.247-.494.301-.63l.055-.275z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#B46762"
            d="M8.208 5.875s-.11-.247-.356-.165c0 0-.329.192-.301.603 0 0-.055.466.356.823 0 0-.082-.247.027-.686l.274-.575z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#FFF"
            d="M11.553 5.354s.027-.22.438-.302c0 0 .63-.055.905.137 0 0 .274.302.274.494 0 0-.11.082-.247.11 0 0-.548.11-.767.082 0 0-.439-.028-.494-.11 0 0-.054-.027-.11-.411M8.894 5.628s.137-.52.41-.576c0 0 .604-.164 1.015.028 0 0 .247.164.192.274 0 0-.164.356-.247.438l-.192.028s-.51.046-.712-.055c-.055-.027-.439-.11-.466-.137"
            transform="translate(0 2.2)"
          />
          <path
            fill="#171A1D"
            d="M9.579 5.494c.001-.093.031-.428.39-.441.015 0 .03.002.045.007.086.03.366.15.335.438-.001.014-.006.028-.012.041-.036.072-.17.304-.402.28 0 0-.26-.025-.35-.281-.005-.014-.007-.029-.006-.044"
            transform="translate(0 2.2)"
          />
          <path
            fill="#FFF"
            d="M9.935 5.258c0 .083-.067.15-.15.15-.084 0-.151-.067-.151-.15 0-.083.067-.15.15-.15.084 0 .151.067.151.15"
            transform="translate(0 2.2)"
          />
          <path
            fill="#191B1E"
            d="M11.909 5.436s.055-.411.52-.329c0 0 .385.192.22.548 0 0-.137.22-.329.22 0 0-.384.027-.411-.439"
            transform="translate(0 2.2)"
          />
          <path
            fill="#FFF"
            d="M12.265 5.313c0 .083-.067.15-.15.15-.084 0-.151-.067-.151-.15 0-.084.067-.151.15-.151.084 0 .151.067.151.15"
            transform="translate(0 2.2)"
          />
          <path
            fill="#F7AC86"
            d="M10.922 5.272s-.329.904-.301 1.233c0 0 .246-1.069.301-1.233"
            transform="translate(0 2.2)"
          />
          <path
            fill="#F4A07A"
            d="M10.525 4.545s.11.33.027.658c0 0-.37.754-.37 1.028 0 0 0 .22-.11.411 0 0-.219.165-.054.411 0 0 .137.165.383.247 0 0 .466.055.905-.137 0 0 .22 0 .164-.247 0 0-.246-.329-.246-.466 0 0 0-1.562.082-1.809"
            transform="translate(0 2.2)"
          />
          <path
            fill="#D3805F"
            d="M10.02 7.01s.297.208.464.208c.027 0 .575-.22.767-.247 0 0 .45-.027.225.123 0 0-.124.127-.545.194 0 0-.525.2-.911-.278"
            transform="translate(0 2.2)"
          />
          <path
            fill="#9B4630"
            d="M11.141 7.108s-.137.045-.191.136c0 0 .164 0 .191-.136"
            transform="translate(0 2.2)"
          />
          <path
            fill="#AA624F"
            d="M9.798 8.095s-.138-.015-.162-.058c-.007-.014-.002-.031.025-.052.382-.024.41.192 1.316.083 0 0 .603-.083 1.014-.275 0 0 .494-.191.466-.11 0 0-.164.056-.301.138 0 0-.302.164-.466.247 0 0-.439.301-1.042.219 0 0-.713-.192-.85-.192"
            transform="translate(0 2.2)"
          />
          <path
            fill="#7C2A1A"
            d="M9.799 8.082c.139 0 .823.185.852.193.59.08 1.027-.215 1.031-.218.164-.082.465-.246.468-.247.112-.068.245-.118.289-.133-.044-.01-.257.056-.443.128-.408.19-1.011.275-1.017.275-.57.07-.796.01-.977-.038-.106-.028-.198-.053-.336-.044-.017.013-.023.024-.018.033.014.027.1.045.151.051m1.057.232c-.068 0-.138-.005-.21-.014-.008-.003-.714-.192-.848-.192h-.001c-.015-.002-.146-.018-.172-.065-.008-.015-.01-.039.029-.068l.003-.002h.003c.145-.01.24.015.349.044.179.047.402.106.966.038.006 0 .606-.084 1.01-.273.102-.04.434-.164.479-.122.004.004.01.013.005.028l-.002.006-.006.002c-.001 0-.164.055-.299.136-.003.002-.304.166-.467.247-.002.002-.347.235-.84.235"
            transform="translate(0 2.2)"
          />
          <path
            fill="#000"
            d="M15.226 16.368l-3.983.555-1.41.252H9.83c-.056.007-1.266.164-1.611.706 0 0-.55.649-.306 1.251l.008.017c.046.072.406.636.445.778.004.013.004.025.002.038-.015.082-.1.448-.502.538 0 0-.585.097-.605.387 0 .01.001.02.004.03l.24.863c.008.03.03.053.06.062.047.015.136.023.301-.01.052-.01.101.026.108.077.008.058.024.127.056.17.016.023.043.034.07.034.117-.001.51-.008.724-.05 0 0 .151 0 .252-.303l.086-.302c.01-.032.034-.056.065-.064.293-.072 1.757-.434 1.96-.44l.012-.002.29-.048.015-.001h.335c.007 0 .013 0 .02-.002l.879-.196c.015-.003.03-.01.04-.02.033-.03.094-.108.13-.286 0 0 .137-.964.325-1.272.016-.025.042-.04.072-.042l.75-.047c.01 0 .02-.002.03-.007.059-.025.258-.154.386-.75"
            transform="translate(0 2.2)"
          />
          <path
            fill="#810A08"
            d="M12.453 16.923l2.42-.403s.353-.152.302.1l-.201.807s-.05 0-.353.05l-.958.101-.202.505.958-.101s0 .1-.05.302l-.202.555-.05.1-.908.051s-.353.756-.403 1.31c0 0 0 .303-.202.354l-.958.252s-.1.05 0-.403c0 0 .656-2.168.958-2.673v-.05h-.353l.202-.857z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#871B19"
            d="M9.982 17.276l2.42-.353-.252.958-.958.1-.252.555.958-.151s.05.05 0 .353l-.201.555s-.807.1-1.009.1l-.201.656 1.16-.151s.05 0 0 .302c0 0-.102.353-.152.655 0 0-.807.05-1.16.152l-1.008.302s-.29-.05-.17-.226l.316-.773.157-.816.504-1.26H9.78l.201-.958z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#86160E"
            d="M9.908 17.396l-.162.81c-.01.045-.046.08-.092.087-.177.026-.6.12-.581.423 0 .014.005.028.012.041.063.127.417.838.483 1.006.008.02.01.04.007.06-.021.118-.11.604-.198.78 0 0-.252.505-.302.706 0 0-.05.605-.252.656 0 0-.391.07-.636.064-.065-.002-.114-.061-.104-.126l.009-.063c.01-.079-.064-.144-.14-.121-.152.045-.358.087-.39-.006l-.196-.787c-.004-.016-.005-.033-.001-.05.013-.057.067-.184.298-.222 0 0 .684-.147.754-.584.002-.014 0-.028-.003-.042-.027-.112-.162-.636-.337-.826-.008-.008-.014-.016-.018-.025-.057-.114-.372-.843.458-1.445l.007-.004c.053-.034.751-.473 1.279-.464.068 0 .118.065.105.132"
            transform="translate(0 2.2)"
          />
          <path
            fill="#FFF"
            d="M9.831 17.377l-.202.756s-.655.1-.655.555c0 0 0 .15.353.806 0 0 .252.605-.05 1.059 0 0-.152.252-.253.403 0 0-.201.706-.201.857v.05l-.605.051.05-.453s-.403.201-.605.15l-.151-.755s.605-.152.857-.404c0 0 .353-.353.05-.857 0 0-.655-.857-.201-1.361 0 0 .706-.857 1.613-.857"
            transform="translate(0 2.2)"
          />
          <path
            fill="#DC2829"
            d="M9.125 18.637s.252-.252.656-.302h.201l-.453 1.058-.404-.756z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#DA2826"
            d="M11.293 18.083l1.11-.152-.454 1.21-.202.605-1.11.152.152-.404.908-.1s.302-.606.353-.959c0 0 .15-.201-.505-.1l-.403.05.151-.302z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#000"
            d="M13.463 19.063c-.04-.055.04-.117.083-.064.233.29 1.05 1.377 1.075 1.655 0 0 .25.753-.45.906l-.008.002c-.097.005-1.737.078-2.618-.656 0 0 1.108.604 2.116.504h.004c.05-.008 1.091-.178.555-1.256l-.005-.008-.752-1.083zM7.915 18.486s-1.058-1.009-.857-1.714c0 0 .151-.454 1.11-.404 0 0 1.462.202 2.268.706 0 0-1.361-.554-2.218-.403 0 0-.706.1-.656.655 0 0 .101.706.353 1.16"
            transform="translate(0 2.2)"
          />
          <path
            fill="#FFF"
            d="M10.083 17.326l2.219-.302-.202.706-.958.1-.353.807 1.008-.1-.15.655-1.11.1-.202.908 1.26-.202-.2.757s-1.766.201-2.068.353l.555-1.967.453-1.058-.453.05.201-.807zM12.554 17.024l2.52-.454-.201.756-1.361.151-.252.757 1.008-.151-.202.705-1.058.101s-.353 1.311-.353 1.614l-.958.252s.605-2.017.806-2.521l.252-.555-.453.05.252-.705z"
            transform="translate(0 2.2)"
          />
          <path
            fill="#E22A2B"
            d="M14.823 17.477L13.713 17.629 13.562 18.032 14.722 17.881z"
            transform="translate(0 2.2)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
